.text-and-contact {
  &-content {
    display: flex;
    flex-direction: column;

    height: 100%;

    justify-content: space-between;
  }

  &-info {
    text-align: right;

    .links {
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        align-items: flex-end;
      }
    }
  }

  .mirrored {
    .text-and-contact {
      &-info {
        text-align: left;

        .links {
          justify-content: flex-start;
        }
      }

      &-contact {
        text-align: right;
      }
    }
  }
}
