.stage {
  &.full-width {
    display: flex;

    align-items: center;

    .row {
      position: relative;
      z-index: 1;
    }
  }
}
