.investment-quiz {
  &-wrapper {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    // min-height: calc(100vh - 80px - 64px); // 80px nav and 64px padding-top

    justify-content: space-between;
  }

  &-step {
    &-meta {
      display: flex;

      align-items: center;
    }

    &-count {
      font-size: rem(16px);

      color: $secondary;
    }

    &-headline {
      font-size: rem(18px);

      margin-top: rem(4px);
      margin-bottom: 0;

      color: $secondary;
    }

    .form-check:not(:last-child) {
      margin-bottom: rem(14px);
    }
  }
}
