.stage {
  &.promotion {
    display: flex;

    align-items: center;

    .row {
      position: relative;
      z-index: 1;
    }

    .headline {
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .teaser {
      font-size: rem(36px);

      text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .promotion {
      &-container {
        position: relative;

        @include media-breakpoint-up(md) {
          max-width: 80%;
          margin: 0 auto;
        }
      }

      &-info {
        display: flex;

        width: 195px;
        height: 195px;
        margin: 0 auto 40px;
        padding: 25px;

        border-radius: 50%;
        background-color: $primary;

        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(md) {
          position: absolute;
          top: -105px;
          left: -200px;
        }

        .preline {
          font-size: rem(30px);
          font-weight: $font-weight-semi-bold;
          font-style: italic;

          margin: 0;

          transform: rotate(-10deg);
        }
      }
    }
  }
}
