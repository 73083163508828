.teaser-and-image {
  &.large {
    img {
      width: 100%;
      height: auto;
    }
    .row {
      &.mirrored {
        .order-2 { // text
          @include media-breakpoint-up(md) {
            padding-right: 32px;
          }
        }
      }

      &:not(.mirrored) {
        .order-2 { // text
          @include media-breakpoint-up(md) {
            padding-left: 32px;
          }
        }
      }
    }
  }
}
