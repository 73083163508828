.text-and-bullets {
  &.numbered {
    .list {
      counter-reset: list-number;

      li {
        counter-increment: list-number;

        &::before {
          content: counter(list-number);
        }
      }
    }
  }
}
