.text-and-numbers {
  .card {
    border: none;
    background-color: $primary;
    box-shadow: none;

    .headline {
      color: $white;
    }

    .teaser {
      margin-top: 4px;

      color: $white;
    }

    .list {
      margin-bottom: 0;
      padding-left: 0;

      li {
        display: flex;

        width: 100%;
        margin-bottom: 8px;

        &::before {
          display: none;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-value {
    width: 35%;

    text-align: right;

    .headline {
      margin-bottom: 0;
    }
  }

  &-text {
    margin-left: 12px;
  }
}
