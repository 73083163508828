.world-map {
  .chart {
    > div {
      height: 600px;

      @include media-breakpoint-down(lg) {
        height: 350px;
      }
    }
  }
}
