.card-tai {
  .headline {
    text-transform: uppercase;
  }

  .card {
    &-body {
      padding: 4rem 3rem;

      @include media-breakpoint-down(md) {
        padding: 2rem 1.8rem;
      }
    }
  }

  > img {
    @include media-breakpoint-down(md) {
      object-position: 65%;
    }
  }
}
