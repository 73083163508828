.accordion,
.accordion-flush {
  &-item {
    padding: 20px 0;

    border-bottom: 1px solid $gray-400 !important;

    &:first-child {
      border-top: 1px solid $gray-400 !important;
    }
  }

  &-button {
    font-size: rem(20px);
    font-weight: $font-weight-semi-bold;

    padding-right: $accordion-button-padding-x * 2;
    padding-left: $accordion-button-padding-x;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      left: auto;

      margin-top: -$accordion-icon-width / 2;
    }
  }

  &-body {
    padding-right: $accordion-button-padding-x * 2;
    padding-left: $accordion-button-padding-x;
  }
}
