@import 'Mixins';
@import 'Variables';

@import 'Vendors';

@import 'Base';
@import 'Components';
@import 'Helpers';

.bg_color_main {
  background-color: $brand-light;
}

.bg_color_second {
  background-color: $brand-secondary;
}

.bg_color_main_light {
  background-color: #f5f5f5;
}

.bg_color_main_dark {
  background-color: $brand-dark;
}

.bg_color_tertiary {
  background-color: $brand-tertiary;
}

.bg_color_fourth {
  background-color: $brand-fourth;
}

.bg_color_primary {
  background-color: $brand-primary;
}

.container-fluid {
  overflow: auto; /* safari margin fix */
}

#main {
  flex: 1;
}

.btn {
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: 400;
}


h1 {
  font-weight: 600;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 600;
}

h4 {
  font-weight: 400;
}

h5 {
  font-weight: 400;
}

h6 {
  font-weight: 600;
}

.display-1 {
  color: $brand-tertiary;
  font-weight: 600;
}

.display-2 {
  color: $brand-tertiary;
  font-weight: 600;
}
.display-3 {
  color: $brand-tertiary;
  font-weight: 400;
}
.display-4 {
  color: $brand-fourth;
  font-weight: 400;
}
.display-5 {
  color: $brand-secondary;
  font-weight: 400;
}
.display-6 {
  color: $brand-primary;
  font-weight: 400;
}

.op-85 {
  opacity: 0.85;
}

.upper {
  text-transform: uppercase;
}

.footer {
  &.block {
    .body-text {
      font-size: rem(16px);
      font-weight: 200;
    }
    .block-items-link {
      a {
        font-size: rem(16px);
        font-weight: 200;
      }
    }
    .footerlinks {
      .link-sm {
        font-weight: 400;
      }
    }
    .divider {
      height: unset;
      hr {
        height: 1px;
      }
    }
  }
}

.link-dark {
  text-decoration: underline;
  font-weight: 200;
}
