.table-element {
  &.grouped {
    .table-group-heading {
      border-bottom-width: $border-width;
      border-bottom-color: $border-color;

      &:hover,
      &:focus,
      &:active {
        box-shadow: inset 0 0 0 9999px $table-hover-bg;

        th {
          box-shadow: none;
        }
      }
    }
  }
}
