.links {
  display: flex;

  margin-top: -$size-xxs;
  margin-right: -$size-xxs * 0.75;
  margin-left: -$size-xxs * 0.75;

  align-items: center;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    flex-direction: column;

    align-items: flex-start;
  }

  > * {
    margin-top: $size-xxs;
    margin-right: $size-xxs * 0.75;
    margin-left: $size-xxs * 0.75;
  }
}
