// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.5rem;
$dropdown-font-size: rem(16px);
$dropdown-color: $dark;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-divider-margin-y: $spacer / 2 !default;
$dropdown-box-shadow: $box-shadow !default;

$dropdown-link-color: $dark;
$dropdown-link-hover-color: $dark;
$dropdown-link-hover-bg: $white;

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: $white;

$dropdown-link-disabled-color: $gray-500 !default;

$dropdown-item-padding-y: rem(12px);
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x !default;

$dropdown-dark-color: $gray-300 !default;
$dropdown-dark-bg: $gray-800 !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $white !default;
$dropdown-dark-link-hover-bg: rgba($white, 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color: $gray-500 !default;
