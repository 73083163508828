.news {
  &-list {
    &-date {
      font-weight: $font-weight-regular;
    }

    .news-list {
      padding-bottom: 5px;
    }
  }

  &-details {
    .container,
    .container-fluid {
      --bs-gutter-x: 0.75rem;                       // .containers inside .rows have bigger paddings because of how bootstrap
    }                                               // calculates them (.row sets --bs-gutter-x to 1.5 rem and we need to fix that)

    section {
      &.container,
      &.container-fluid {
        padding-right: 0;                           // because child CE's are inside basic .container-fluid we need to stretch
        padding-left: 0;                            // them to apply background colors for the full width
      }
    }

    &-categories {
      &-list {
        margin-bottom: 0;
        padding-left: 0;

        list-style-type: none;
      }

      &-item {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-link {
        color: $body-color;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;

          color: $primary;
        }
      }
    }

    .pagination {
      display: flex;

      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-direction: column;

        align-items: center;
      }

      .page-item {
        display: flex;

        max-width: 450px;

        flex-grow: 1;
        flex-basis: 0;
        align-items: center;
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }

        &:nth-child(2) {
          justify-content: flex-end;
        }

        a {
          display: flex;

          align-items: center;

          span {
            padding: 10px;
          }

          svg {
            color: $primary;

            flex-shrink: 0;
          }
        }
      }
    }
  }

  &-related {
    &-item {
      .card {
        &-body {
          padding: $card-spacer-x/2;
        }
      }
    }
  }
}
