// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg: transparent !default;

$table-th-font-weight: null !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-striped-order: odd !default;

$table-group-separator-color: $dark;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;

$table-variants: (
  'primary':    shift-color($primary, $table-bg-scale),
  'secondary':  shift-color($secondary, $table-bg-scale),
  'success':    shift-color($success, $table-bg-scale),
  'info':       shift-color($info, $table-bg-scale),
  'warning':    shift-color($warning, $table-bg-scale),
  'danger':     shift-color($danger, $table-bg-scale),
  'light':      $light,
  'dark':       $dark,
) !default;
// scss-docs-end table-variables
