.card {
  overflow: hidden;

  transition: box-shadow 0.15s ease-in-out;

  box-shadow: $box-shadow;

  &:hover,
  &:focus,
  &:active {
    box-shadow: $box-shadow-hover;
  }
}
