.preline {
  font-weight: 400;
  line-height: 1;

  position: relative;

  display: inline-block;

  padding: 7px 10px;

  vertical-align: middle;
  text-transform: uppercase;

  color: $brand-tertiary;
  border: 2px solid #fff;
}

// .bg_color_second {
//   .preline {
//     color: $white;
//   }
// }
