.accordion-and-categories {
  .tab-content {
    position: relative; // to cut box-shadow only on one side

    background-color: $white;

    .container-fluid {
      padding-right: 0;
      padding-left: 0;

      > .container {
        padding-right: 0;
        padding-left: 0;

        .row {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }

  .nav {
    &-tabs {
      margin-left: 50px;

      border-bottom: none;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
      align-items: flex-end;

      // align-items: end;
      // flex-wrap: wrap-reverse; // have to use end instead of flex-end here

      .nav-link {
        font-size: $font-size-base;
        font-weight: $font-weight-base;

        margin: 0;
        padding: 0.75rem 2rem;

        transition: all 0.15s ease-in-out;

        color: $body-color;
        border: none;
        background-color: $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

        @include media-breakpoint-down(lg) {
          text-align: center;

          flex-grow: 1;
        }
        &.active {
          z-index: 1; // to make it overlap

          margin-top: -8px; // reduce spacing on wrap
          padding: 1rem 2rem;

          color: $white;
          background-color: $primary;
        }

        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);

          &::before {
            display: none;
          }
        }
      }
    }
  }
}
