.text-and-icon-list {
  &.icon-card-wall {
    .card {
      &-image {
        display: flex;

        align-items: center;
      }
    }
  }
}
