// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-color: $gray-300 !default;

$border-radius: rem(6px);
$border-radius-sm: rem(6px);
$border-radius-lg: rem(6px);

$border-radius-pill: 50rem !default;
