.accordion {
  &.standard {
    .accordion,
    .accordion-flush {
      &-button {
        color: $secondary;
      }
    }
  }
}
