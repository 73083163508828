.teaser-and-image {
  display: flex;

  align-items: center;

  img {
    display: block;

    margin: 0 auto;
  }

  &.fixed {
    clip-path: inset(0);

    img {
      position: fixed !important;

      pointer-events: none; // Safari fix
    }
  }
}
