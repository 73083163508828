.dropdown {
  display: flex;

  align-items: center;

  &-toggle {
    &-split {
      padding: 5px 10px;

      border: none;
      outline: none;
      background-color: transparent;

      appearance: none;
    }
  }

  &-item {
    font-weight: $font-weight-regular;

    &.active {
      font-weight: $font-weight-bold;
    }
  }

  &-text {
    position: relative;

    &:hover,
    &:focus,
    &:active {
      &::before {
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 0;

        display: block;

        height: 1px;

        content: '';

        background-color: $dark;
      }
    }
  }
}
