.stage {
  &.simple {
    min-height: 45rem;

    @include media-breakpoint-down(md) {
      min-height: auto !important;
    }

    img {
      height: 100%;
    }

    .links {
      a {
        font-weight: 400;
      }
    }

    .row {
      flex-grow: 1; // without this if there is a short text the content will be too narrow
    }
  }
}
