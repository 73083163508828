.text {
  &.two-columns {
    .teaser {
      @include media-breakpoint-up(lg) {
        column-count: 2;
        column-gap: rem(32px);
      }
    }
  }
}
