// Popovers

$popover-font-size: $font-size-base; // affects only body
$popover-bg: transparent;
$popover-max-width: 394px;
$popover-border-width: 0;
$popover-border-color: transparent;
$popover-border-radius: $border-radius-lg;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: none;

$popover-header-bg: $popover-bg;
$popover-header-color: $dark;
$popover-header-padding-y: $spacer / 2;
$popover-header-padding-x: 0;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $spacer / 2;
$popover-body-padding-x: 0;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $white;

$popover-arrow-outer-color: $white;
