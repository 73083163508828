.quote-and-reference {
  &-content {
    margin-bottom: 0;
  }

  &-text {
    padding-bottom: 10px;

    .teaser {
      font-weight: 500;
      font-style: italic;
    }
  }

  &-reference {
    height: 100%;

    background-color: $secondary;

    .teaser {
      color: $white;
    }
  }

  &-meta {
    .preline {
      font-weight: $font-weight-light;

      color: $body-color;
    }
  }
}
