.facts-and-bullets {
  &.standard {
    .card {
      flex-direction: row;

      border-color: transparent;
      background-color: transparent;
      box-shadow: none;

      &-image {
        flex-shrink: 0;
      }
    }
  }
}
