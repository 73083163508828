.btn-close {
  font-size: rem(14px);
  font-weight: 400;

  display: inline-block;

  padding: 0;

  text-transform: none;

  color: $tertiary;
  border: none;
  background-color: transparent;

  appearance: none;
}
