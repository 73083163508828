.accordion {
  &.simple {
    .headline {
      color: $secondary;
    }

    .accordion,
    .accordion-flush {
      padding-right: $accordion-button-padding-x;
      padding-left: $accordion-button-padding-x;

      &-button {
        font-weight: $font-weight-regular;

        padding-right: $accordion-button-padding-x;
        padding-left: $accordion-button-padding-x * 2;

        color: $black;

        &::after,
        &::before {
          position: absolute;
          top: 50%;
          right: auto;
          left: 0;

          display: block;

          width: 16px;
          height: 2px;
          margin-top: 0;

          content: '';
          transition: transform 0.15s ease-in-out;
          transform: translateY(-50%) rotate(0deg);

          background-color: $primary;
          background-image: none;
        }

        &.collapsed {
          &::before {
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }

      &-body {
        padding-right: $accordion-button-padding-x;
        padding-left: $accordion-button-padding-x * 2;
      }
    }
  }
}
