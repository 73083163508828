.timeline {
  &.timeline-centered {
    .timeline {
      &-card {
        .card {
          max-width: 650px;
          margin: 0 auto;
        }
      }


      &-image {
        text-align: center;
      }
    }
  }
}
