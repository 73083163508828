.footer {
  &.contact {
    .link {
      &-inline {
        margin-right: 8px;
        padding: 12px;

        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

        border: 1px solid $primary;
        border-radius: 50%;

        &:hover,
        &:focus,
        &:active {
          color: $white;
          background-color: $primary;
        }
      }
    }

    .footer-contact-info {
      &-list {
        margin-bottom: 0;
        padding-left: 0;

        list-style-type: none;

        + .footer-contact-info-button {
          margin-top: 52px;
        }
      }

      &-item {
        position: relative;

        display: flex;

        margin-bottom: 12px;

        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-icon {
        position: absolute;
        top: 0;
        left: 0;

        width: 18px;
        height: 18px;

        svg {
          width: 100%;
          height: 100%;

          transition: color 0.15s ease-in-out;

          color: $primary;

          object-fit: contain;
        }
      }

      &-link {
        padding-left: 32px;

        p {
          display: inline-block; // fix for html content
        }
      }

      &-button {
        @include media-breakpoint-up(lg) {
          margin-bottom: -12px;
        }
      }
    }
  }
}
