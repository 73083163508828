.fact-sheet {
  .card {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
  }

  &-item {
    img {
      display: block;
    }
  }
}
