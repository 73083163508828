// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  'blue':       $blue,
  'indigo':     $indigo,
  'purple':     $purple,
  'pink':       $pink,
  'red':        $red,
  'orange':     $orange,
  'yellow':     $yellow,
  'green':      $green,
  'teal':       $teal,
  'cyan':       $cyan,
  'white':      $white,
  'gray':       $gray-600,
  'gray-dark':  $gray-800
) !default;
// scss-docs-end colors-map
