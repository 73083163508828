.stage {
  &-img {
    @include media-breakpoint-down(md) {
      position: relative !important;

      width: auto !important;
      max-width: 100vw;
      margin-right: -50vw;
      margin-left: -50vw;
    }
  }
}
