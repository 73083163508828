.search-list {
  &-info {
    margin-right: -$container-padding-x;
    margin-left: -$container-padding-x;
    padding: 80px $container-padding-x;
  }

  &-results {
    padding: 80px 0 40px;

    .card {
      .match {
        display: inline-block;

        color: $primary;
      }
    }
  }
}
