.popover {
  z-index: $zindex-sticky;

  &-header {
    font-size: $font-size-heading-desktop-5;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-heading-mobile-5;
    }
  }

  &-content {
    font-weight: $font-weight-base;

    overflow: hidden;

    padding: rem(30px);

    border-radius: $border-radius-lg;
    background-color: $white;
    box-shadow: $box-shadow-lg;
  }
}
