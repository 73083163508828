.timeline {
  &.alternating {
    .timeline {
      &-card {
        @include media-breakpoint-up(md) {
          .timeline-card-content {
            width: 50%;
            padding-right: 15px;

            .timeline-image {
              text-align: right;

              img {
                padding-right: 40px;
              }
            }

            .card {
              position: relative;

              overflow: visible;

              margin-right: 0;
              margin-left: auto;

              &-body {
                text-align: right;

                &:after {
                  position: absolute;
                  top: 32px;
                  right: -27px;

                  display: block;

                  width: 11px;
                  height: 11px;

                  content: '';
                  transform: translateX(-50%);

                  border: 2px solid $white;
                  border-radius: 50%;
                  background-color: $secondary;
                }

                &::before {
                  position: absolute;
                  top: 31px;
                  right: -1px;

                  display: block;

                  width: 0;
                  height: 0;

                  content: '';
                  transform: translateX(100%);

                  border-top: 7px solid transparent;
                  border-bottom: 7px solid transparent;
                  border-left: 7px solid #e0e0e0;
                }
              }
            }
          }

          &-alternate {
            .timeline-card-content {
              margin-right: 0;
              margin-left: auto;
              padding-right: 0;
              padding-left: 15px;

              .timeline-image {
                text-align: left;

                img {
                  padding-right: 0;
                  padding-left: 40px;
                }
              }

              .card {
                position: relative;

                margin-right: auto;
                margin-left: 0;

                &-body {
                  text-align: left;

                  &:after {
                    right: 0;
                    left: -27px;

                    transform: translateX(50%);
                  }

                  &::before {
                    right: auto;
                    left: -1px;

                    transform: translateX(-100%);

                    border-right: 7px solid #e0e0e0;
                    border-left: none;
                  }
                }
              }
            }
          }
        }

        &-content {
          width: 100%;
          padding-right: 0;

          .timeline-image {
            text-align: center;

            img {
              margin: 0;
            }
          }

          .card {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
