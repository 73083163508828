.information-slider {
  &-description {
    margin-bottom: 15px;
  }

  &-value {
    margin-bottom: 35px;
  }

  &-control {
    display: block;

    max-width: 850px;
    margin: 0 auto 35px;
  }
}
