.btn {
  &-light {
    &:hover,
    &:focus,
    &:active {
      background-color: shade-color($light, 10%);
    }
  }

  &-dark {
    &:hover,
    &:focus,
    &:active {
      background-color: shade-color($dark, 30%);
    }
  }

  &-warning {
    &:hover,
    &:focus,
    &:active {
      background-color: shade-color($warning, 15%);
    }
  }

  &:not([class*='btn-outline']) {
    border: none;
  }

  @each $color, $value in $theme-colors {
    &-outline-#{$color} {
      &:hover,
      &:focus,
      &:active {
        color: $value;
        background-color: transparentize($value, 0.92);
      }
    }
  }
}
