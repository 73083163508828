.stage {
  &.video {
    display: flex;

    align-items: center;

    .links {
      justify-content: center;
      @include media-breakpoint-down(md) {
        flex-direction: row;
      }
    }

    .video-js {
      position: absolute;
      z-index: -1;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transform: translateY(-50%);

      object-fit: cover;
    }
  }
}
