.tabs-element {
  .tab-content {
    .container-fluid {
      padding-right: 0;
      padding-left: 0;

      > .container {
        padding-right: 0;
        padding-left: 0;

        .row {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }

  .nav {
    &-pills {
      .nav-link {
        font-size: $font-size-base;
        font-weight: $font-weight-base;

        margin: 8px;
        padding: 0.5rem 2rem;

        transition: all 0.15s ease-in-out;

        color: $body-color;
        background-color: $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

        &.active {
          color: $white;
          background-color: $primary;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);

          &::before {
            display: none;
          }
        }
      }
    }
  }
}
