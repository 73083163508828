.text-and-bullets {
  &.standard {
    .list {
      // list-style: square;
      li {
        margin-bottom: 1rem;
        // &::before {
        //   display: none;
        // }
      }
    }
  }
}
