.spinner {
  position: fixed;
  z-index: 999;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  background-color: $body-bg;

  align-items: center;
  justify-content: center;
}
