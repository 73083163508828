.news {
  &-list {
    &.thumbnail-teaser {
      .card {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;

        &-image {
          margin-right: 40px;

          flex-shrink: 0;
        }


        .news-list {
          &-content {
            display: flex;

            align-items: center;
          }
        }
      }
    }
  }
}
