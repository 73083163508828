.google-maps {
  &.standard {
    .row {
      [class|='col'] {
        &:first-child {
          padding: 0;
        }
      }
    }
  }
}
