.menu-pages {
  .links {
    margin-right: 0;
    margin-left: 0;

    > * {
      margin-right: 0;
      margin-left: 0;
      font-size: 1rem;
    }

    .btn-sm {
      padding: 10px 25px;
    }
  }
  .nav-link {
    font-size: 1rem;
  }
  .navbar-link {
    > * {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $brand-black;
  font-weight: 200;
  &.active {
    font-weight: 400;
  }
  &:hover {
    &::before {
      height: unset;
      background-color: unset;
    }
  }
  &:focus {
    &::before {
      height: unset;
      background-color: unset;
    }
  }
  &:active {
    &::before {
      height: unset;
      background-color: unset;
    }
  }
}