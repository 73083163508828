.contact-card {
  .card {
    max-width: 500px;
    margin: 0 auto;

    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-direction: row;

      max-width: 100%;

      flex-wrap: nowrap;
    }

    &-body {
      @include media-breakpoint-up(lg) {
        display: flex;

        padding-left: 10%;

        align-items: center;
      }
      @include media-breakpoint-up(xl) {
        padding-left: 15%;
      }

      .headline {
        padding: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &-info {
    width: 100%;
    max-width: 500px;

    &-item {
      display: flex;

      margin-bottom: rem(12px);

      align-items: center;

      svg {
        margin-right: rem(14px);

        color: $primary;
      }
    }
  }

  &-additional {
    margin-bottom: 0;

    list-style-type: none;

    &-item {
      margin-bottom: rem(12px);

      color: $primary;
    }
  }
}
