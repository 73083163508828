.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;

  .breadcrumb {
    &-item {
      a {
        color: $body-color;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;

          color: $primary;
        }
      }
    }
  }
}
