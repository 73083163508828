// .bg_color_second {
//   .teaser {
//     color: $white;
//   }
// }

.teaser {
  position: relative;

  p:last-child:not(:only-child),
  p:last-of-type:not(:only-child) {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
}

.teaser ul,
ul.list {
  position: relative;

  padding-left: 20px;

  list-style: none; /* Remove default bullets */

  li {
    position: relative;

    margin-bottom: $size-xs;
    padding-left: rem(8px);

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      font-size: 28px;
      font-weight: bold; /* If you want it to be bold */
      line-height: 1;

      position: absolute;
      top: 9px;
      left: -0.5rem;

      display: inline-block; /* Needed to add space between the bullet and the text */

      width: 10px;
      height: 10px;

      content: '';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      transform: translateX(-100%);
      // margin-right: 1rem; /* Also needed for space (tweak if needed) */
      // margin-left: -2rem;
      text-align: right;

      color: $primary; /* Change the color */
      background-color: $primary;
    }
  }

  &-icon {
    padding-left: 30px;

    li {
      &:before {
        left: 1.5rem;

        width: 24px;
        height: 24px;

        content: '';

        background-image: url('../../../Images/svg/checked.svg');
        background-size: cover;

        filter: contrast(0.4); // faking secondary color for NFS
      }
    }
  }
}


.footer {
  .teaser {
    &.block-items-text {
      a {
        text-decoration: none;
      }
    }
  }
}
