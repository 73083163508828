.accordion-gallery {
  .card {
    &-body {
      padding: $card-spacer-x/2;
      padding-bottom: $card-spacer-x;
    }

    .headline {
      margin-bottom: 0;
    }
  }

  .accordion,
  .accordion-flush {
    padding: 0;

    &-button {
      font-weight: $font-weight-regular;

      padding-top: 0;
      padding-right: $accordion-button-padding-x;
      padding-bottom: 0;
      padding-left: $accordion-button-padding-x * 2;

      color: $black;

      &::after,
      &::before {
        position: absolute;
        top: 50%;
        right: auto;
        left: 0;

        display: block;

        width: 16px;
        height: 2px;
        margin-top: 0;

        content: '';
        transition: transform 0.15s ease-in-out;
        transform: translateY(-50%) rotate(0deg);

        background-color: $primary;
        background-image: none;
      }

      &.collapsed {
        &::before {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }

    &-body {
      padding-top: 0;
      padding-right: $accordion-button-padding-x;
      padding-bottom: 0;
      padding-left: $accordion-button-padding-x * 2;
    }

    &-highlight {
      padding: 16px 24px;

      background-color: $primary;

      .teaser {
        color: $white;
      }
    }
  }
}
