.contact-card {
  &.secondary {
    .card {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none;

      &-body {
        background-color: $primary;
      }

      .headline {
        color: $white !important;
      }
    }

    .contact-card {
      &-info {
        &-item {
          a,
          svg {
            color: $white;
          }
        }
      }
    }
  }
}
