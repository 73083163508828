// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-control-transition: opacity 0.15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-opacity: 0.5 !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-active-opacity: 1 !default;
$carousel-indicator-transition: opacity 0.6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;
$carousel-caption-padding-y: 1.25rem !default;
$carousel-caption-spacer: 1.25rem !default;

$carousel-control-icon-width: 2rem !default;

$carousel-control-prev-icon-bg: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'#{$carousel-control-color}\'><path d=\'M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z\'/></svg>') !default;
$carousel-control-next-icon-bg: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'#{$carousel-control-color}\'><path d=\'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z\'/></svg>') !default;

$carousel-transition-duration: 0.6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg: $black !default;
$carousel-dark-caption-color: $black !default;
$carousel-dark-control-icon-filter: invert(1) grayscale(100) !default;
