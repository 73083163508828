.text-and-icon-list {
  transition: box-shadow 0.15s ease-in-out;

  border-radius: $card-border-radius;
  box-shadow: $box-shadow;

  &:hover,
  &:focus,
  &:active {
    box-shadow: $box-shadow-hover;
  }
}
