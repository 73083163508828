.comparison-gallery {
  .card {
    &-image {
      padding: rem(24px) $card-spacer-x;

      &.bg_color_second,
      &.bg_color_primary {
        .headline {
          color: $white;
        }
      }
    }

    &-body {
      padding-top: 0;
    }

    .bullets {
      &-item {
        display: flex;
      }

      &-icon {
        margin-right: 10px;

        border-bottom: 1px solid transparent;

        span {
          display: flex;

          width: 12px;
          height: 24px;

          align-items: center;
        }
      }

      &-content {
        border-bottom: 1px solid #e0e0e0;

        flex-grow: 1;
      }
    }
  }
}
