.news {
  &-list {
    &.vertical {
      .card {
        flex-direction: row;

        align-items: center;

        &-image {
          height: 100%;

          flex-shrink: 0;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}
