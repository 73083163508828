.investment-calculator {
  &-name {
    font-size: rem(24px);
    font-weight: 500;

    span {
      font-size: rem(32px);
    }
  }

  &-fields {
    padding-left: 30px;
  }

  &-field {
    display: flex;

    align-items: center;

    span {
      font-size: rem(16px);

      width: 120px;

      color: $brand-tertiary;
    }
  }

  &-label {
    color: $dark;
  }

  &-input {
    max-width: 100px;
    margin: 0 15px;

    text-align: right;

    color: $brand-tertiary;
  }

  .form-check:not(:last-child) {
    margin-bottom: rem(14px);
  }

  .chart {
    > div {
      height: 750px;

      @include media-breakpoint-down(lg) {
        height: 500px;
      }
    }
  }
}
