.stage {
  &.centered {
    display: flex;

    align-items: center;

    .row {
      position: relative;
      z-index: 1;

      &.text-center {
        .headline {
          &::after {
            left: 50%;

            transform: translateX(-50%);
          }
        }
      }
    }

    .headline {
      position: relative;

      padding-bottom: 45px;

      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 80px;
        height: 5px;

        content: '';

        background-color: currentColor;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      }
    }

    .teaser {
      font-size: rem(22px);
      font-weight: 700;

      text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
}
