.gallery {
  &.slider {
    .slick-track {
      display: flex !important;
      overflow: hidden;

      align-items: center !important;
    }

    .slider-item {
      img {
        margin: 0 auto;
      }
    }

    &.grayscale {
      .slider-item {
        img {
          transition: filter 0.15s ease-in-out;

          filter: grayscale(1);

          &:hover,
          &:focus,
          &:active {
            filter: grayscale(0);
          }
        }
      }
    }
  }
}

[id|='gallery-slider'] {
  .popover-content {
    transform: translateY(50%);
  }
}
