.menu-section {
  &.on-page {
    display: flex;
    overflow: auto;

    height: 6rem;

    .nav {
      display: flex;

      margin: 0 auto;

      flex-wrap: nowrap;

      &-item {
        display: flex;

        flex-shrink: 0;
        align-items: center;
      }

      &-link {
        font-size: 1.5rem;
        font-weight: 400;

        margin-right: rem(12px);  // set up margins to fix underlines
        margin-left: rem(12px);   // set up margins to fix underlines
        padding-right: rem(12px);
        padding-left: rem(12px);

        text-decoration: none;
        text-transform: uppercase;

        color: $secondary;

        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
        }

        &:hover {
          &::before {
            height: unset;

            background-color: unset;
          }
        }
        &:focus {
          &::before {
            height: unset;

            background-color: unset;
          }
        }
        &:active {
          &::before {
            height: unset;

            background-color: unset;
          }
        }
      }
    }
  }
}
