.facts-and-bullets {
  .bullets {
    position: relative;

    padding-left: 14px;

    &-dot,
    &-checkmark {
      .bullets-item {
        margin-bottom: 8px;
        padding-left: rem(8px);

        &::before {
          font-size: 28px;
          font-weight: bold;
          line-height: 1;

          position: absolute;
          left: 0.55rem;

          display: inline-block;

          content: '\2022';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          transform: translateX(-100%);
          text-align: right;

          color: $primary;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-checkmark {
      .bullets-item {
        padding-left: rem(12px);

        &::before {
          left: 0;

          width: 13px;
          height: 13px;

          transform: translateY(6px);

          color: transparent;
          background-image: url('data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'UTF-8\'%3F%3E%3Csvg id=\'SVGDoc\' width=\'17\' height=\'14\' xmlns=\'http://www.w3.org/2000/svg\' version=\'1.1\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' xmlns:avocode=\'https://avocode.com/\' viewBox=\'0 0 17 14\'%3E%3Cdefs%3E%3C/defs%3E%3Cdesc%3EGenerated with Avocode.%3C/desc%3E%3Cg%3E%3Cg%3E%3Ctitle%3EIcon feather-check%3C/title%3E%3Cpath d=\'M14.4361,2.09741v0l-8.20607,9.80518v0l-3.73003,-4.4569v0\' fill-opacity=\'0\' fill=\'%23ffffff\' stroke-dashoffset=\'0\' stroke-linejoin=\'round\' stroke-linecap=\'round\' stroke-opacity=\'1\' stroke=\'%232cabd8\' stroke-miterlimit=\'20\' stroke-width=\'3\'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}
