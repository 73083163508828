// Modals

// Padding applied to the modal body
$modal-inner-padding: $spacer !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;
