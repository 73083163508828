.google-maps-and-contacts {
  position: relative;

  > .row {
    [class|='col'] {
      &:first-child {
        padding: 0;
      }
    }
  }

  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .row {
      height: 100%;
    }
  }

  .card {
    border: none;
    background-color: $primary;
    box-shadow: none;

    .headline {
      color: $white;
    }

    .contact-list {
      margin: 0;
      padding: 0;

      list-style-type: none;

      &-item,
      &-link {
        color: $white;
      }

      &-item {
        display: flex;

        padding: 15px 0;

        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        &:last-child {
          border-bottom: none;
        }
      }

      &-link {
        margin-left: 15px;
      }
    }
  }
}
