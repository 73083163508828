
$brand-primary: #029BDD;
$brand-secondary: #7A9A01;
$brand-tertiary: #005892;
$brand-fourth: #DE0066;

$brand-white: #fff;
$brand-black: #000;

$brand-light: #EBEBEB;
$brand-dark: #0A0A0A;

$brand-success: #28a745;
$brand-danger: #dc3545;
$brand-warning: #ffc107;
$brand-info: #17a2b8;
