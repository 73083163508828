// Breadcrumbs

$breadcrumb-font-size: null !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 1rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: null !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $black;
$breadcrumb-divider: quote('—');
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;
