.stage {
  &.viewport-height {
    @include media-breakpoint-down(md) {
      min-height: auto !important;
    }

    .row {
      flex-grow: 1; // without this if there is a short text the content will be too narrow
    }
  }
}
