.quotes {
  &.simple {
    .card {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none;
    }

    .quotes-text {
      padding: 0;
    }

    .quotes-info {
      display: flex;

      align-items: center;

      .preline {
        font-weight: $font-weight-light;

        margin-bottom: 0;
      }

      .headline {
        margin-right: rem(24px);
        margin-bottom: 0;
      }
    }
  }
}
