.news {
  &-list {
    &.card-list {
      .card {
        &-image {
          margin-right: 40px;

          flex-shrink: 0;
        }

        &-body {
          display: flex;

          align-items: center;

          .news-list {
            &-content {
              margin-right: 40px;
            }

            &-info {
              display: flex;

              margin-top: 20px;

              align-items: center;
            }

            &-date {
              margin-right: 40px;

              flex-shrink: 0;
            }

            &-link {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}
