.amcharts {
  &-range-selector {
    &-wrapper {
      padding: 0 !important;
    }

    &-range-wrapper {
      display: none;
    }

    &-period-wrapper {
      float: none !important;
    }

    &-period-title {
      display: none;
    }

    &-period-button {
      font-family: $font-family-base;
      font-size: $font-size-text-sm;

      margin-right: 30px !important;
      margin-left: 0 !important;
      padding: 0 !important;

      transition: color 0.15s ease-in-out;

      color: $tertiary;
      border: none;
      background-color: transparent;

      appearance: none;

      &.active {
        color: $black;
      }
    }
  }
}
