.breadcrumb {
  &-item {
    &.active {
      font-weight: $font-weight-regular;

      &::before {
        font-weight: $font-weight-base;
      }
    }
  }
}
