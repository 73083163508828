.contact-gallery {
  .card {
    padding-bottom: 40px;

    border-color: transparent;
    background-color: transparent;
    box-shadow: none;

    .headline {
      margin-bottom: 0;
    }
  }

  &-item-icon {
    font-size: rem(24px);

    transition: color 0.15s ease-in-out;

    color: $primary;

    &:hover,
    &:focus,
    &:active {
      color: shade-color($primary, 15%);
    }
  }
}
