.accordion {
  &.secondary {
    .headline {
      color: $secondary;
    }

    .accordion,
    .accordion-flush {
      &-button {
        padding-right: $accordion-button-padding-x;
        padding-left: $accordion-button-padding-x * 2;

        &::after {
          right: auto;
          left: 0;
        }
      }

      &-body {
        padding-right: $accordion-button-padding-x;
        padding-left: $accordion-button-padding-x * 2;
      }
    }
  }
}
